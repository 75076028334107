@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: YuGothicUI !important;
  src: url(./assets/fonts/YuGothic-Regular-01.ttf) format('ttf');
  font-weight: 400;
  /* Regular */
  font-style: normal;
}

@font-face {
  font-family: YuGothicUI;
  src: url(./assets/fonts/YuGothicUI-Bold-02.ttf) format('ttf');
  font-weight: 700;
  /* Bold */
  font-style: normal;
}

body {
  font-family: YuGothicUI, sans-serif;
}

body.language-vi {
  * {
    font-family: Arial, sans-serif !important;
    letter-spacing: 0 !important;
  }
}

body.language-ja {
  * {
    font-family: YuGothicUI, sans-serif !important;
  }
}

body.language-en {
  * {
    font-family: Arial, sans-serif !important;
    letter-spacing: 0 !important;
  }
}

@layer components {
  .tab-admin-list {
    &.ant-tabs > .ant-tabs-nav::before {
      @apply mt-[21px] border-b border-customGray;
      width: auto;
    }

    &.ant-tabs .ant-tabs-tab {
      @apply px-14 py-2.5 tracking-[0.7px];
    }

    &.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
      @apply m-0;
    }

    &.ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
      @apply font-bold text-primary;
    }

    &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
      @apply overflow-visible;
    }

    &.ant-tabs .ant-tabs-ink-bar {
      @apply bottom-[-1.5px] h-1 rounded-full bg-primary;
    }

    &.ant-tabs > .ant-tabs-nav {
      @apply mb-0;
    }
  }

  .table-permission {
    &.ant-table-wrapper thead > tr > th {
      @apply min-w-fit max-w-40 border-b border-customGray bg-white px-3 py-1.5 text-center;
    }

    &.ant-table-wrapper thead > tr > th:nth-child(n + 4) {
      @apply min-w-36;
    }

    &.ant-table-wrapper tr td {
      @apply !py-1.5 px-2;
    }

    &.ant-table-wrapper thead > tr > th::before {
      content: none !important;
    }

    &.ant-table-wrapper tr td:first-child {
      @apply min-w-28 text-left font-bold;
    }

    &.ant-table-wrapper tr tr td + td {
      font-weight: normal !important;
      text-align: left;
    }

    /* 問診票回答一覧 */
    &.ant-table-wrapper tr:first-child > td:first-child + td + td {
      @apply min-w-20 px-2 text-left;
    }

    /* 検査項目設定	検査項目一覧*/
    &.ant-table-wrapper tr:first-child + tr {
      > td:first-child + td {
        @apply min-w-32 px-2 text-left;
      }

      > td:first-child + td + td {
        @apply px-2 text-left;
      }
    }

    /* 診察券		診察券一覧*/
    &.ant-table-wrapper tr:first-child + tr + tr {
      > td:first-child + td {
        @apply px-2 text-left;
      }
    }

    /* 商品一覧 */
    &.ant-table-wrapper tr:nth-child(5),
    tr:nth-child(6) {
      > td:first-child {
        @apply min-w-20 font-normal;
      }
    }
    &.ant-table-wrapper tr:nth-child(5),
    tr:nth-child(6) {
      > td:first-child {
        @apply max-w-28;
      }
      > td:first-child + td {
        @apply min-w-48;
      }
    }

    /* 顧客一覧 */
    &.ant-table-wrapper
      tr:first-child
      + tr
      + tr
      + tr
      > td:first-child
      + td
      + td {
      @apply px-2 text-left;
    }
  }

  .modal {
    .ant-modal-content {
      @apply rounded-[10px] p-0;
    }
  }

  .popover-permission {
    .ant-popover-inner {
      @apply p-0;
    }

    .ant-popover-arrow::before,
    .ant-popover-arrow::after {
      @apply bg-[#545454];
    }
  }

  .add-role-modal form article {
    @apply leading-none;
  }

  .category-with-items {
    .ant-collapse-header {
      @apply !p-0;
    }

    .ant-collapse-expand-icon {
      height: unset !important;
      padding-inline-start: 0 !important;

      svg {
        @apply !h-auto !w-[15px];
      }
    }

    .space-y-2 > :not([hidden]) ~ :not([hidden]) {
      @apply !m-0;
    }

    .ant-collapse-header {
      @apply !items-center;
    }
  }

  .category-item-list {
    .ant-list-split .ant-list-item:last-child {
      border-block-end: 1px solid rgba(5, 5, 5, 0.06);
    }
  }
}

@layer utilities {
  .shadow-custom {
    box-shadow: 0px 3px 5px #00000029;
  }

  .text-primary {
    color: #137695;
  }
}

.ant-notification-notice-wrapper {
  background-color: transparent !important;
}

.ant-notification-notice-message {
  margin-left: 0 !important;
  margin-bottom: 0 !important;
}

.ant-radio-inner {
  background-color: #fff !important;
}

.question-preview {
  .ant-radio-wrapper {
    cursor: unset !important;

    span.ant-radio,
    input.ant-radio-input {
      cursor: text !important;
      pointer-events: none !important;
    }

    span.ant-radio-inner {
      border-color: #707070 !important;
    }
  }

  .ant-checkbox-wrapper {
    cursor: unset !important;

    span.ant-checkbox,
    input.ant-checkbox-input {
      cursor: text !important;
      pointer-events: none !important;
    }

    span.ant-checkbox-inner {
      border-color: #707070 !important;
    }
  }

  .ant-checkbox-wrapper-checked {
    cursor: unset !important;
    pointer-events: none !important;

    .ant-checkbox-checked .ant-checkbox-inner {
      @apply border-[#707070];
    }
  }
}

.ant-radio-wrapper .ant-radio-inner::after {
  background-color: #137695 !important;
  width: 20px;
  height: 20px;
  margin-block-start: -10px;
  margin-inline-start: -10px;
}

.ant-table-thead > tr > th::before {
  width: 0 !important;
  height: 0 !important;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input)
  .ant-select-selector {
  background: #f0f3f7;
}

.ant-btn-primary:disabled {
  background: #d9e0e5;
}

.ant-row {
  flex-wrap: nowrap;
}

.scrollbar-custom::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scrollbar-custom::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.scrollbar-custom::-webkit-scrollbar-thumb {
  background: #d9e0e5;
}

/* Handle on hover */
.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-colon::after {
  content: ':';
}

.custom-period::after {
  content: '.';
}

.ant-table-wrapper .ant-table {
  scrollbar-color: unset;
}

.custom-report-table {
  .ant-table-thead {
    height: 30px;
  }

  .ant-table-thead > tr > th {
    background-color: #127594;
    /* @apply bg-gradient-to-r from-cyan-500 to-blue-500; */
    @apply font-bold text-[#ffffff];
    @apply self-start;
    @apply pl-2 !important;
  }

  .ant-table-tbody > tr {
    height: 49px;
  }

  .ant-table-tbody > tr > td {
    background-color: #ffffff;
    @apply text-[#545454];
    @apply self-start;
    @apply pl-2 !important;
  }

  &.ant-table-wrapper .ant-table-thead tr th {
    @apply p-0;
  }

  &.ant-table-wrapper .ant-table-tbody tr td {
    @apply p-0;
  }

  .ant-table-tbody > .active-row > td {
    font-size: 29px;
    background-color: #e8f2f5;
    @apply font-semibold;
  }
}

.column-report-style-1 {
  width: 12.5%;
}

.column-report-style-2 {
  width: 12.5%;
}

.column-report-style-3 {
  width: 25%;
}

.column-report-style-4 {
  width: 25%;
}

.column-report-style-4 {
  width: 25%;
}

.column-report-style-1 > div {
  @apply font-bold;
  font-size: 14px;
  @apply pl-3 !important;
}

.btn-primary-hover {
  &:hover {
    @apply !border-[#df2475] !text-[#df2475];
  }
}

.group-setting-table-bg-thead {
  &.ant-table-wrapper thead > tr > th {
    @apply bg-[#ffffff];
  }

  &.ant-table-wrapper .ant-table-tbody > tr > td:first-child {
    @apply pl-10 text-left !important;
  }
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  @apply border-[#BDCBD5];
}

.ant-radio-wrapper .ant-radio-inner {
  @apply h-[18px] w-[18px] border-[#BDCBD5];

  &::after {
    width: 28px;
    height: 28px;
    margin-block-start: -14px;
    margin-inline-start: -14px;
  }
}

.active {
  @apply bg-[#c5d3d4];
}

.active-sub-menu ~ .default-active {
  @apply bg-[#c5d3d4];
}

.btn-error-hover.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  @apply border-error text-error;
}

.td-table-center {
  &.ant-table-wrapper .ant-table-tbody > tr > td {
    @apply text-center;
  }
}

fieldset {
  display: contents;
}

.reset-outline {
  border: unset !important;

  &:focus {
    outline: none !important;
    box-shadow: unset !important;
  }
}

.table-admin {
  &.ant-table-wrapper thead > tr > th {
    @apply border-b border-t border-customGray bg-white py-1.5 text-center font-normal;
  }

  tbody .ant-table-cell {
    padding: 8px 12px !important;
    border-bottom: unset !important;
  }

  tr .ant-table-selection-column {
    padding-inline-start: 0px !important;
    padding-left: 2px !important;
    padding-right: 4px !important;

    .ant-checkbox .ant-checkbox-inner,
    .ant-checkbox .ant-checkbox-input {
      width: 20px;
      height: 20px;
    }

    .ant-checkbox .ant-checkbox-inner:after {
      inset-inline-start: 35%;
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
      transform: translate(-25%, -50%) scale(1);
      width: 12px;
      height: 12px;
    }
  }

  &.ant-table-wrapper thead > tr > th::before {
    content: none !important;
  }

  .custom-scrollbar {
    @apply max-h-24 overflow-y-auto whitespace-pre-line;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #eff3f6;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #cdd6dd;
}

.add-border + .add-border {
  border-top: 1px solid #d9e0e5;
}

.courseSelect,
.departmentSelect {
  .ant-select-selector {
    cursor: pointer !important;
  }
}

.courseSearch .ant-select-selection-item {
  max-width: calc(100% - 20px);
}

.patientCheckbox {
  .ant-checkbox-inner:after {
    inset-inline-start: 26%;
  }
}

.popupSelectSearchCustom,
.popupSelectMultipleCustom,
.coursePopupSelect,
.departmentPopupSelect {
  &.ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: inherit;
  }

  .ant-select-item-option-selected {
    font-weight: unset !important;
  }

  .ant-checkbox .ant-checkbox-input:focus-visible + .ant-checkbox-inner {
    outline: unset !important;
  }
}

.popupSelectSearchCustom {
  &.ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #eef3fa;
  }
}

.course-register-list-table,
.product-master-list-table {
  &.ant-table-wrapper thead > tr > th {
    @apply border-b border-t border-customGray bg-white p-2 text-center font-medium;
  }

  &.ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: unset;
    padding: 8px;
    text-align: center;
  }

  .ant-checkbox .ant-checkbox-inner,
  .ant-checkbox .ant-checkbox-input {
    width: 20px;
    height: 20px;
  }

  .ant-checkbox .ant-checkbox-inner:after {
    inset-inline-start: 35%;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    transform: translate(-25%, -50%) scale(1);
    width: 12px;
    height: 12px;
  }

  &.ant-table-wrapper thead,
  &.ant-table-wrapper tbody {
    tr > .ant-table-selection-column {
      text-align: left;
      padding-left: 2px;
    }
  }
}

.custom-radio-group {
  @apply overflow-hidden rounded-md border border-customGray;

  .ant-radio-button-wrapper {
    @apply min-w-[100px] px-3 text-center tracking-[.7px] text-default;
    border: unset;
  }

  .ant-radio-button-wrapper-checked:hover,
  .ant-radio-button-wrapper-checked {
    @apply bg-primary text-white;
  }
}

.in-course-inspection-items {
  .ant-collapse-header {
    @apply !items-center bg-[#EFF3F6] !p-0;
  }

  .ant-collapse-expand-icon {
    padding-inline-start: 0 !important;
  }

  .ant-collapse-content-box {
    @apply !p-0;
  }
}

.drawer-schedule {
  .ant-drawer-content-wrapper {
    width: 1000px !important;
    height: calc(100vh - 225px);
    right: 10px;
    top: 60px;
    border-radius: 10px;

    @media (max-width: 768px) {
      width: 100% !important;
      right: 0;
    }
  }

  .ant-drawer-body {
    overflow: hidden;
    padding: 15px;
  }

  .ant-drawer-content {
    border-radius: 10px;
  }
}

.table-schedule tr td:first-child {
  width: 50px;
}

.table-schedule .ant-table-container {
  border-radius: 0;
  border-inline-start: 0 !important;
  border-top: 0 !important;

  .ant-table-header {
    border-radius: 0 !important;
  }

  .ant-table-thead th {
    border-top: 1px solid #bfc6cb;
    border-color: #bfc6cb !important;
    padding: 5px 15px !important;
    text-align: center;
    background: #fff;
  }

  .ant-table-header th:last-child {
    border: none !important;
    border-left: 1px solid #bfc6cb !important;
  }

  .ant-table-row-level-0 td {
    padding: 0 !important;
    text-align: center;
    background: #f0f3f7;
    border-color: #bfc6cb !important;
  }

  .ant-table-row td:first-child,
  .ant-table-header th:first-child {
    border: none;
    background: #fff;
    max-width: 40px !important;
    border-start-start-radius: 0 !important;
  }

  .ant-table-row td:first-child,
  .ant-table-thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 1000;
  }
}

.checkbox-position {
  .ant-checkbox + span {
    padding-inline-start: 2px;
    padding-inline-end: 0px;
  }

  .ant-checkbox .ant-checkbox-inner,
  .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item
    input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }

  .ant-checkbox .ant-checkbox-inner:after {
    inset-inline-start: 35%;
  }
}

.scenario-list-table {
  /* remove border tr */
  .ant-table-tbody > tr > td {
    border: unset;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ant-table-thead > tr > th {
    background: white;
    border-top: 1px solid #f0f0f0;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.sub-tab-basic-setting,
.tab-product-master {
  .ant-tabs-nav {
    @apply my-2.5 ml-6;
  }

  .ant-tabs-nav::before {
    border-bottom: unset;
  }

  .ant-tabs-tab {
    @apply py-0;
  }

  .ant-tabs-tab-active {
    @apply text-primary;
  }

  .ant-tabs-tab-active .ant-typography {
    @apply font-bold text-inherit;
  }

  .ant-tabs-ink-bar {
    background: unset;
  }
}

.basic-setting-tabs {
  @apply rounded-md bg-[white] tracking-wider;

  .ant-tabs-nav::before {
    @apply mx-2.5 border-b border-[#BDCBD5];
  }

  .ant-tabs-content-holder {
    @apply bg-[#F0F3F7];
  }

  &.ant-tabs {
    .ant-tabs-nav {
      @apply mb-2.5;
    }

    .ant-tabs-nav .ant-tabs-tab {
      @apply px-4 py-3;
    }

    .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      @apply font-bold;
    }

    .ant-tabs-nav .ant-tabs-nav-wrap {
      @apply mx-2.5 overflow-visible;
    }

    .ant-tabs-nav .ant-tabs-ink-bar {
      @apply h-1 rounded-full;
    }

    .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
      @apply ml-16;
    }

    .ant-tabs-ink-bar {
      @apply bottom-[-1.5px] h-1 rounded-full bg-primary;
    }
  }
}

.collapse-custom-admin-registration {
  &.ant-collapse {
    .ant-collapse-item .ant-collapse-header {
      @apply p-2.5;
    }

    .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon {
      padding-inline-end: 8px;
    }

    .ant-collapse-content .ant-collapse-content-box {
      @apply px-2.5 pb-3;
    }
  }
}

.sub-tab-basic-setting {
  &.ant-tabs {
    .ant-tabs-nav {
      @apply m-0 ml-4;
    }
    .ant-tabs-nav .ant-tabs-ink-bar {
      background: unset;
    }

    .ant-tabs-nav .ant-tabs-tab {
      @apply px-0 py-1.5;
    }
  }

  .ant-tabs-content-holder {
    @apply bg-white;
  }
}

.row-basic-setting + .row-basic-setting {
  @apply border-t border-customGray;
}

.item-collapse-admin-registration + .item-collapse-admin-registration {
  @apply mt-5 border-t border-customGray pt-5;
}

.border-top-between + .border-top-between {
  @apply border-t border-customGray;
}

.select-multi-options .ant-select-prefix {
  height: 17px;
}

.select-multi-options .ant-select-selection-placeholder {
  inset-inline-start: 30px;
  padding-left: 3px;
}

.select-multi-options .ant-select-selection-search {
  margin: 0;
}

.border-left-between + .border-left-between {
  @apply border-l border-[#BFC6CB];
}

.checkbox-csv {
  .ant-checkbox {
    align-self: start;
    padding-top: 2px;
    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
    }
    .ant-checkbox-inner:after {
      inset-inline-start: 35%;
    }
  }
}

.btn-disabled:disabled {
  background: #bdcbd5;
  color: white;
}

.preview-csv-result-table {
  border: 1px solid #d9e0e5;
  table {
    text-align: center;
  }

  .ant-table-thead {
    border: 1px solid #d9e0e5;
  }
  .ant-table-thead > tr > th {
    background: #eff3f6;
    padding: 12px;
    text-align: center;
    font-weight: normal;
  }
  .ant-table-tbody > tr > td {
    padding: 12px;
  }
}

.label-csv-result {
  @apply border border-[#C6C6C6] bg-white px-1.5 py-0.5;
}
.csvTable > :where(.css-dev-only-do-not-override-12kbrax).ant-table-wrapper .ant-table-tbody >tr >th, :where(.css-dev-only-do-not-override-12kbrax).ant-table-wrapper .ant-table-tbody >tr >td {
  background-color: white !important;
}

.csvTable tbody tr:last-child td {
  border: none;
}

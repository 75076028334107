.container_layout {
  :global {
    .ant-menu-item {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  .set_line_height {
    line-height: 34px;
    height: 34px;
  }
}